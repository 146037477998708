<template>
    <div>
        <vx-card class="h-full order-planned-sales pt-4 pb-8" v-if="userHasPermission('view_planned_sales')">

            <h2>Planumsätze</h2>

            <div class="table-list">
                <vue-bootstrap4-table
                        :rows="data"
                        :columns="columns"
                        :config="config"
                        :actions="actions"
                        @on-change-query="onChangeQuery"
                        @on-change-per-page="onChangePerPage"
                        :total-rows="total_rows"
                        @on-click-add="openCreateModal"
                        columnSelection>


                    <template slot="empty-results">
                        Keine Planumsätze gefunden.
                    </template>

                    <template slot="row_actions" slot-scope="props">
                        <div class="column-actions-group">
                            <vx-tooltip text="Bearbeiten" position="left">
                                <vs-button color="success" type="filled" icon="edit"
                                           size="small"
                                           @click="openEditPlannedSalesModal(props.row.id)"></vs-button>
                            </vx-tooltip>
                            <vx-tooltip text="Löschen" position="left">
                                <vs-button color="danger" type="filled" icon="delete"
                                           size="small"
                                           @click="deletePlannedSales(props.row.id)"></vs-button>
                            </vx-tooltip>
                        </div>
                    </template>

                    <template slot="client_company_name" slot-scope="props">
                          <router-link :to="`/customers/${ props.row.client_id}`">{{props.row.client.company_name}}</router-link>
                    </template>

                    <template slot="orders_number" slot-scope="props">
                        <span v-if="props.row.order_id != null">{{props.row.orders.invoice_number}}</span>
                        <i v-else>Kein Angebot</i>
                    </template>

                    <template slot="orders_title" slot-scope="props">

                        <span v-if="props.row.order_id != null">{{props.row.orders.title}}</span>
                        <i v-else>Kein Angebot</i>
                    </template>

                    <template slot="orders_invoice_number" slot-scope="props">
                      <router-link :to="`/offers/${props.row.orders.id}/planned-sales` ">{{props.row.orders.invoice_number}}</router-link>
                    </template>

                    <template slot="date" slot-scope="props">
                        <span class="vbt-table-td">
                            <span>{{props.row.date_month_from}}/{{props.row.date_year_from}}</span>
                        </span>
                    </template>

                    <template slot="creator" slot-scope="props">
                        <span class="vbt-table-td">
                            <div class="flex  mb-4">
                                <gravatar-img :email="props.row.creator.email" class="rounded-full shadow-md" width="30"
                                              height="30"/>
                                <span class="mt-1 ml-2">{{
                                    props.row.creator.detail.first_name
                                  }} {{ props.row.creator.detail.last_name }}</span>
                            </div>
                        </span>
                    </template>


                </vue-bootstrap4-table>

                <planned-sales-modal :active.sync="plannedSalesModalActive" @close="closePlannedSalesModal()"
                                     :plannedSalesId.sync="selectedPlannedSalesId"></planned-sales-modal>



                <div class="planned-result-sum float-right mt-4 text-right">
                    <div class="mt-2">
                        <b>Geplanter Umsatz <small>(insg.)</small>: {{plannedSalesSum}}</b><br>
                    </div>
                </div>
            </div>

            <div class="clearfix"></div>

            <planned-sales-calender-table ref="calender"></planned-sales-calender-table>

        </vx-card>

        <div  v-if="!userHasPermission('view_planned_sales')">
            Keine Berechtigung für diese Seite!
        </div>
    </div>
</template>

<script>
    import VueBootstrap4Table from '@/plugins/vue-bootstrap4-table-master/src/components/VueBootstrap4Table'

    import ApiService from "../../../api";
    import moment from 'moment';
    import QueryHelper from "../../../mixins/helper/query.helper";
    import * as qs from "qs";
    import PriceHelper from "../../../mixins/helper/price.helper";
    import vSelect from 'vue-select'
    import {mapGetters} from "vuex";
    import GravatarImg from "vue-gravatar/src/components/GravatarImg";
    import PlannedSalesModal from "../../../components/planned-sales/planned-sales-modal";
    import PlannedSalesCalenderTable from "../../../components/planned-sales/planned-sales-calender-table";
    import cloneDeep from "lodash/cloneDeep";

    export default {
        components: {
            PlannedSalesModal,
            PlannedSalesCalenderTable,
            VueBootstrap4Table,
            'v-select': vSelect,
            GravatarImg
        },
        data() {
            return {
                // Edit/Create Modal
                selectedPlannedSalesId: null,
                plannedSalesModalActive: false,

                dataFetched: false,
                data: [],
                offerData: [],
                actions: [
                    {
                        btn_text: 'Neuer Planumsatz hinzufügen',
                        class: "btn-info",
                        event_name: "on-click-add",
                        color: 'success'
                    }
                ],
                columns: [
                    {
                        label: "Kunde",
                        name: "client.company_name",
                        filter: {
                            type: "simple",
                            placeholder: "Suchen..."
                        },
                        sort: true,
                        row_text_alignment: 'text-left',
                        column_text_alignment: 'text-left',
                        showCol: true,
                        width: '18%',
                    },
                    {
                        label: "A-Nummer",
                        slot_name: "orders_number",
                        name: "order_id",
                        filter: {
                            type: "simple",
                            placeholder: "Suchen..."
                        },
                        sort: true,
                        row_text_alignment: 'text-left',
                        column_text_alignment: 'text-left',
                        showCol: true,
                        width: '5%',
                    },
                    {
                        label: "Angebot",
                        slot_name: "orders_title",
                        name: "order_id",
                        filter: {
                            type: "simple",
                            placeholder: "Suchen..."
                        },
                        sort: true,
                        row_text_alignment: 'text-left',
                        column_text_alignment: 'text-left',
                        showCol: true,
                        width: '25%',
                    },
                    {
                        label: "Title",
                        name: "title",
                        filter: {
                            type: "simple",
                            placeholder: "Suchen..."
                        },
                        sort: false,
                        row_text_alignment: 'text-left',
                        column_text_alignment: 'text-left',
                        width: '20%',
                        showCol: true,
                    },
                    {
                        label: "Monat",
                        name: "date_month_from",
                        sort: true,
                        row_text_alignment: 'text-left',
                        column_text_alignment: 'text-left',
                        showCol: true,

                        width: '220px',
                        filter: {
                            type: "simple",
                            placeholder: "Suchen..."
                        },
                    },
                    {
                        label: "Jahr",
                        name: "date_year_from",
                        sort: true,
                        row_text_alignment: 'text-left',
                        column_text_alignment: 'text-left',
                        showCol: true,

                        width: '220px',
                        filter: {
                            type: "simple",
                            placeholder: "Suchen..."
                        },
                    },

                    {
                        label: "Betrag",
                        name: "sum_netto",
                        sort: true,
                        row_text_alignment: 'text-left',
                        column_text_alignment: 'text-left',
                        formatter: PriceHelper.formatCurrency,
                        width: '12%',
                        showCol: true,
                    },
                    {
                        label: "Erstellt von",
                        name: "creator.detail.first_name",
                        slot_name: "creator",
                        filter: {
                            type: "simple",
                            placeholder: "Suchen..."
                        },
                        sort: true,
                        row_text_alignment: 'text-left',
                        column_text_alignment: 'text-left',
                        column_classes: 'type-column',
                        width: '20%',
                        showCol: true,
                    },
                    {
                        label: "Erstellt",
                        name: "created_at",
                        sort: true,
                        row_text_alignment: 'text-left',
                        column_text_alignment: 'text-left',
                        showCol: false,
                        width: '200px',
                        formatter: (value) => {
                            return moment(new Date(value)).format('DD.MM.YY HH:mm');
                        },
                    },
                    {
                        label: "Letzer Update",
                        name: "updated_at",
                        sort: true,
                        row_text_alignment: 'text-left',
                        column_text_alignment: 'text-left',
                        showCol: false,
                        width: '200px',
                        formatter: (value) => {
                            return moment(new Date(value)).format('DD.MM.YY HH:mm');
                        },
                    },
                    {
                        label: "Actions",
                        slot_name: "row_actions",
                        sort: false,
                        row_text_alignment: 'text-left',
                        column_text_alignment: 'text-left',
                        column_classes: 'actions-column',
                        showCol: true,
                        width: '80px',

                    }],
                config: {
                    checkbox_rows: false,
                    show_refresh_button: false,
                    show_reset_button: false,
                    highlight_row_hover_color: '#f8f8f8',
                    server_mode: true,
                    global_search: {
                        visibility: false
                    },
                    per_page: 25
                },
                queryParams: {
                    sort: [{
                        caseSensitive: true,
                        name: "date_year_from",
                        order: "asc"
                    },{
                        caseSensitive: true,
                        name: "date_month_from",
                        order: "asc"
                    }],
                    filters: [],
                    global_search: "",
                    per_page: 25,
                    page: 1,
                },
                total_rows: 0,
                orderTypes: [],
            }
        },
        created() {
            this.fetchData(true)
        },
        computed: {
            ...mapGetters(['userHasPermission', 'userId', 'user']),
            requestParams() {
                let queryParams = QueryHelper.parseRequestParams(this.queryParams);

                queryParams.order = {
                    field: "date_year_from",
                    direction: "asc"
                }

                queryParams.order1 = {
                    field: "date_month_from",
                    direction: "asc"
                }

                return queryParams;
            },
            plannedSalesSum() {
                if (!this.data) {
                    return PriceHelper.formatCurrency(0);
                }

                const sum = this.data.reduce((acc, item) => {
                    return acc + item.sum_netto;
                }, 0);

                return PriceHelper.formatCurrency(sum);
            },
            offerSum() {
                if (!this.offerData || !this.offerData.sum_netto) {
                    return PriceHelper.formatCurrency(0);
                }

                return PriceHelper.formatCurrency(this.offerData.sum_netto);
            },

        },
        methods: {
            async fetchData(initial = false) {

                if(this.userHasPermission('view_offer_planned_sales')) {
                    this.$vs.loading();
                    this.fetchPlannedSales();
                    return this.fetchOffer();
                }

            },
            fetchPlannedSales() {

                ApiService.get('order_planned_sales', {
                    params: this.requestParams,
                    paramsSerializer: params => qs.stringify(params, {arrayFormat: 'indices'})
                }).then(response => {
                    this.data = response.data.result;
                    this.total_rows = response.data.total;
                    this.$vs.loading.close()
                })
            },

            fetchOffer() {
                return;
                const id = 2;
                if(id) {
                    return ApiService.get(`orders/${id}`).then(response => {
                        this.offerData = response.data.result;
                    })
                }
            },
            openEditPlannedSalesModal(id) {

                this.selectedPlannedSalesId = id;
                this.plannedSalesModalActive = true;
            },
            deletePlannedSales(id) {

                if (!id) {
                    return
                }
                this.$vs.loading();
                ApiService.delete('order_planned_sales/' + id).then((response) => {

                    this.fetchData();

                    if(response.data.status === 'success') {
                        return this.$vs.notify({
                            title: 'Erfolgreich gelöscht',
                            iconPack: 'feather',
                            icon: 'icon-alert-circle',
                            color: 'success'
                        });
                    }

                    this.$vs.loading.close()

                    this.$vs.notify({
                        title: 'Fehlgeschlagen',
                        text: 'Die Position konnte nicht gelöscht werden.',
                        iconPack: 'feather',
                        icon: 'icon-alert-circle',
                        color: 'danger'
                    })
                }).catch((error) => {
                    this.$vs.notify({
                        title: 'Fehlgeschlagen',
                        text: 'Der Position konnte nicht gelöscht werden.',
                        iconPack: 'feather',
                        icon: 'icon-alert-circle',
                        color: 'danger'
                    })
                })
            },
            employeesPromise() {
                return ApiService.get('users');
            },
            onChangeQuery(queryParams) {
                this.queryParams = queryParams;
                this.fetchData();
            },
            onChangePerPage(value) {
                this.config.per_page = value;
            },
            openCreateModal() {
                this.selectedPlannedSalesId = null;
                this.plannedSalesModalActive = true;
            },
            closePlannedSalesModal(){
                this.plannedSalesModalActive =false;
                this.fetchData();
                this.$refs.calender.fetchData()
            },
            formatDateTime(value) {
                return moment(new Date(value)).format('DD.MM.YYYY')
            },
        }
    }
</script>

<style lang="scss">

</style>
